import React from "react";
import './photo_modal.scss';
import {IoIosClose} from "react-icons/all";
import {CSSTransition} from "react-transition-group";

const PhotoModal = ({image, onDismiss}) => {
  const imageProvided = image !== null;

  let style = {};
  if (image) {
    style = {
      backgroundImage: `url(${image})`,
    };
  }

  return (
    <CSSTransition timeout={300} in={imageProvided} classNames='photo-modal'>
      <div className={'photo-modal'} style={style}>
        {/*https://react-icons.netlify.com/#/icons/io*/}
        <IoIosClose onClick={onDismiss}/>
      </div>
    </CSSTransition>
  );
};

export default PhotoModal;
