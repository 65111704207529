import logo from "../logo.svg";
import React from "react";

export default function Page404() {
  return (
    <div className="App">
      <header className="App-header">
        {/*<img src={logo} className="App-logo" alt="logo" />*/}
        <p>
          <img src={'/logo-color-alt.png'} alt="Masterist"/>
          <h1>
            404
          </h1>
          <p>Здесь ничего нет</p>
        </p>
      </header>
    </div>
  )
}
