import { combineReducers } from "redux";
import landingReducer from "./landingReducer";
import offersReducer from "./offersReducer";
import appointmentReducer from "./appointmentReducer";
import appReducer from "./appReducer";

export default combineReducers({
  landingReducer,
  offersReducer,
  appointmentReducer,
  appReducer,
});
