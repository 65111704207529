import styled from 'styled-components';

const defaultColor = '#c7b39b';

export const HeaderBg = styled.div`
  background-image: ${props => props.img && `url(${props.img})`};
  background-color: ${defaultColor};
`;

export const AvatarBox = styled.div`
  background-image: ${props => props.img && `url(${props.img})`};
  background-color: ${defaultColor};
  width: ${props => props.size ? props.size : "112"}px;
  height: ${props => props.size ? props.size : "112"}px;
`;

export const SbuttonBox = styled.div`
`;

export const Sbutton = styled.div`
  background-image: url("${props => props.img && `/simages/${props.img}.png`}");
`;

export const OfferImgBox = styled.div`
  background-image: ${props => (props.img && `url(${props.img})`) || `url(missing.png)`};
`;

export const ImageBox = styled.div`
  background-image: ${props => props.img && `url(${props.img})`};
`
