import axios from "axios";

export const FETCH_OFFERS_BEGIN   = 'FETCH_OFFERS_BEGIN';
export const FETCH_OFFERS_SUCCESS = 'FETCH_OFFERS_SUCCESS';
export const FETCH_OFFERS_FAILURE = 'FETCH_OFFERS_FAILURE';

const apiEndPoint = process.env['REACT_APP_BACKEND_API'];

export const fetchOffersData = (landing_name) => {
  return dispatch => {
    dispatch(fetchOffersBegin());

    const request = axios.get(`${apiEndPoint}/landing/${landing_name}/get_offers`);

    request
      .then(data => {
        dispatch(fetchOffersSuccess(data['data']));
      })
      .catch(error => {
        dispatch(fetchOffersFailure(error));
      });
  };
};

export const fetchOffersBegin = () => ({
  type: FETCH_OFFERS_BEGIN
});

export const fetchOffersSuccess = offersData => ({
  type: FETCH_OFFERS_SUCCESS,
  payload: offersData
});

export const fetchOffersFailure = error => ({
  type: FETCH_OFFERS_FAILURE,
  payload: { error }
});
