export const APP_MODAL_OPEN  = 'APP_MODAL_OPEN';
export const APP_MODAL_CLOSE = 'APP_MODAL_CLOSE';

export const appModalOpen = () => ({
  type: APP_MODAL_OPEN
});

export const appModalClose = () => ({
  type: APP_MODAL_CLOSE,
});
