import React from 'react';
import './App.scss';
import {Route, Switch} from "react-router-dom";
import Main from "./pages/main";
import Page404 from "./pages/404";
import 'moment/locale/ru';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import {useSelector} from "react-redux";


require('dotenv').config();

const alertOptions = {
  position: positions.TOP_CENTER,
  timeout: 1500,
  offset: '10px',
  transition: transitions.FADE
};

function App() {
  const appState = useSelector(state => state.appReducer);
  // TODO: incapsulate router

  const underModalStyle = {
    height: appState.modalOpen ? '1px' : '100%',
    overflow: appState.modalOpen ? 'hidden' : 'unset'
  };

  return (
    <AlertProvider template={AlertTemplate} {...alertOptions}>
      <main style={underModalStyle}>
        <Switch>
          <Route exact path='/landing/:landing_name' component={Main}/>
          <Route exact path='/:landing_name' component={Main}/>
          <Route path="*" component={Page404} />
        </Switch>
      </main>
    </AlertProvider>
  );
}

export default App;
