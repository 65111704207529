import {APP_MODAL_CLOSE, APP_MODAL_OPEN} from "../actions/appActions";

const initialState = {
  modalOpen: false
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case APP_MODAL_OPEN:
      console.log("appReducer received " + action.type, action.payload);
      return {
        ...state,
        modalOpen: true,
      };

    case APP_MODAL_CLOSE:
      console.log("appReducer received " + action.type, action.payload);
      return {
        ...state,
        modalOpen: false
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
