import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchOffersData} from "../../redux/actions/offersActions";
import {IoIosClose, IoMdTime, IoMdCard} from "react-icons/all";
import {CSSTransition} from "react-transition-group";
import './assign_modal.scss';
import Select from 'react-select';
import Moment from 'react-moment';
import 'react-phone-number-input/style.css';
import {createAppointment, fetchAppointmentsData, resetAppointmentState} from "../../redux/actions/appointmentActions";
import PhoneInput, {isPossiblePhoneNumber} from 'react-phone-number-input';
import {useAlert} from "react-alert";
import {AvatarBox} from "../../pages/main_styles";
import {appModalClose, appModalOpen} from "../../redux/actions/appActions";
import Loading from "../loading";

const AssignModal = ({landing_name, opened, onClose, landing}) => {
  const offersState = useSelector(state => state.offersReducer);
  const appointmentState = useSelector(state => state.appointmentReducer);
  const [selectedOffer, setSelectedOffer] = useState();
  const [selectedDay, setSelectedDay] = useState();
  const [isCheck, setIsCheck] = useState(false);
  const [selectedAssertion, setSelectedAssertion] = useState();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const date = new Date();
  const alert = useAlert();


  useEffect(() => {
    if (opened === true) {
      dispatch(fetchOffersData(landing_name));
    } else {
    }
  }, [opened]);

  useEffect(() => {
    if (selectedOffer && typeof selectedDay == 'number') {
      setSelectedAssertion(false);
      console.log("Start fetch days", selectedOffer, selectedDay);
      dispatch(fetchAppointmentsData(landing_name, {
        date: selectedDay,
        offer_id: selectedOffer.value
      }));
    }
  }, [selectedOffer, selectedDay]);

  const handleCloseModal = () => {
    dispatch(resetAppointmentState());
    setSelectedAssertion(null);
    setSelectedOffer(null);
    setSelectedDay(null);
    setName(null);
    setPhone(null);
    setEmail(null);
    setIsCheck(null);
    onClose();
  };

  const handleOfferChange = (selectedOption) => {
    console.log('Selected option', selectedOption.value, offersState.offers.find(e => e.id === selectedOption.value));
    setSelectedOffer(selectedOption);
  };

  const handelDayChange = (selectedDay) => {
    console.log('Selected date', selectedDay);
    setSelectedDay(selectedDay);
  };

  const handleAssertionChange = (selectedAssertion) => {
    console.log('Selected assertion', selectedAssertion);
    setSelectedAssertion(selectedAssertion);
  };

  const getMainButtonClass = () => {
    let klass = 'MainButton';
    if (!name || !phone || !isPossiblePhoneNumber(phone) || appointmentState.appointmentCreating) {
      klass += ' disabled';
    }

    if (appointmentState.loading) return 'disabled';

    return klass;
  };

  const formValid = () => {
    // console.log(`Validating form ${name} / ${phone} / ${isPossiblePhoneNumber(phone)} / ${selectedAssertion} / ${selectedDay} / ${selectedOffer.hasOwnProperty('value')}`);
    return name && phone && isPossiblePhoneNumber(phone) && selectedAssertion && (selectedDay || selectedDay == 0) && selectedOffer.hasOwnProperty('value');
  };

  const assertDays = [];

  for (let i = 0; i < 20; i++) {
    let classNames = 'dateBoxy center';
    if (selectedDay === i) classNames += ' active';
    assertDays.push(
      <div key={i} className={classNames} onClick={() => {
        handelDayChange(i);
      }}>
        <Moment className='dayName' format='dd' add={{days: i}} locale="ru">{date}</Moment>
        <br/>
        <Moment className='dayDate' format='DD' add={{days: i}}>{date}</Moment>
      </div>
    );
  }

  if (appointmentState.error) {
    // console.log("Error!");
    alert.show('Произошла ошибка!');
  }

  const handleCheckAssertion = () => {
    setIsCheck(true);
  };

  const handleUnCheckAssertion = () => {
    setIsCheck(false);
  };

  const handleCreateAppointment = () => {
    // if (formValid()) console.log('Create appointment');
    // if (!formValid()) console.log('Create appointment false');

    if (formValid()) {
      dispatch(
        createAppointment(landing_name, selectedOffer.value, {
          time: selectedAssertion,
          date: selectedDay,
          name: name,
          phone: phone,
          email: email
        })
      );
    }
  };

  const getOfferInfo = () => {
    return (
      <div className="infoBox">
        <IoMdCard></IoMdCard>
        <span>
          {offersState.offers.find(e => e.id === selectedOffer.value)['price']}&nbsp;₽
        </span>
        <IoMdTime></IoMdTime>
        <span>
          {offersState.offers.find(e => e.id === selectedOffer.value)['duration_plural']}
        </span>
      </div>
    );
  };

  const createCalendarEvent = () => {
    window.open("https://calendar.google.com/calendar/r/eventedit?" +
      `text=Запись+на+${selectedOffer.label}&` +
      `dates=${appointmentState.createAppointmentResponse.body.start}/${appointmentState.createAppointmentResponse.body.end}&` +
      // "details=For+details,+link+here:+http://www.example.com" +
      // "&location=Waldorf+Astoria,+301+Park+Ave+,+New+York,+NY+10022&" +
      "sf=true&" +
      "output=xml", "_blank");
  };

  return (
    <CSSTransition timeout={300} in={opened} classNames='assign-modal'>
      {/*http://reactcommunity.org/react-transition-group/css-transition*/}
      <div className='assign-modal'>
        <IoIosClose className="closeIcon" onClick={handleCloseModal}/>

        {/*{true &&*/}
        {offersState.loading &&
        <Loading absoluted={true} centered={true}/>
        }

        {/*{false &&*/}
        {!offersState.loading &&
        <>
          {isCheck ?
            <div className="checkAssertion">

              {appointmentState.createAppointmentResponse
              && appointmentState.createAppointmentResponse.hasOwnProperty('status')
              && appointmentState.createAppointmentResponse.status === 'success' ?
                <div className="assertionResult">
                  <h1>Запись оформлена</h1>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <div onClick={createCalendarEvent} className={'MainButton hollow'}>
                    <div>
                      Добавить в календарь
                    </div>
                  </div>

                  <div onClick={handleCloseModal} className={'MainButton'}>
                    <div>
                      Закрыть
                    </div>
                  </div>
                </div>
                :
                <div className="checkAssertionForm">
                  <h1>Подтверждение записи</h1>

                  <div className="masterInfo">
                    <AvatarBox className={'AvatarBox'} size={'56'} img={landing.public_profile.avatar}/>
                    <span>{landing.public_landing_profile.title}</span>
                  </div>

                  <div className="otherInfo">
                    <span className='label'>Услуга</span>
                    <span className="labelInfo">
                      {selectedOffer.label}
                    </span>

                    {getOfferInfo()}
                  </div>

                  <div className="otherInfo">
                    <span className='label'>Дата и время записи</span>
                    <span className="labelInfo">
                  <Moment format='dddd, D MMMM, YYYY' add={{days: selectedDay}} locale="ru">{date}</Moment>
                      &nbsp;
                      {selectedAssertion}
                </span>
                  </div>

                  <div className="otherInfo">
                    <span className='label'>Ваше имя</span>
                    <span className="labelInfo">
                  {name}
                </span>
                  </div>

                  <div className="otherInfo">
                    <span className='label'>Ваш телефон</span>
                    <span className="labelInfo">
                  {phone}
                </span>
                  </div>

                  {email &&
                  <div className="otherInfo">
                    <span className='label'>Ваш email</span>
                    <span className="labelInfo">
                  {email}
                </span>
                  </div>
                  }

                  {appointmentState.appointmentCreating &&
                    <Loading centered={true} />
                  }

                  {!appointmentState.appointmentCreating &&
                  <>
                    <div className={getMainButtonClass() + ' hollow'} onClick={handleUnCheckAssertion}>
                      <div>
                        Изменить данные
                      </div>
                    </div>

                    <div className={getMainButtonClass()} onClick={handleCreateAppointment}>
                      <div>
                        Записаться
                      </div>
                    </div>
                    <span className="gpdalert">
                      * Подтвердив запись вы соглашаетесь с политикой сервиса по обработке персональных данных.
                    </span>
                  </>
                  }

                </div>
              }

            </div>
            :
            <div className="assetion">
              <h1>Записаться</h1>

              <span className='label'>Услуга</span>
              <Select
                classNmae={'testInput'}
                value={selectedOffer}
                placeholder={'Выберите...'}
                onChange={handleOfferChange}
                options={offersState.offers.map(offer => {
                  return {value: offer.id, label: offer.name};
                })}
              />

              {selectedOffer &&
              getOfferInfo()
              }

              <hr/>

              <span className='label'>Дата и время записи</span>

              <div className='dateBox'>
                {assertDays}
              </div>

              {selectedDay >= 0 &&
              <h2 className={'afterDate'}>
                <Moment format='dddd, D MMMM, YYYY' add={{days: selectedDay}} locale="ru">{date}</Moment>
              </h2>
              }

              <div className='assertBox'>
                {appointmentState.appointments.map((app, idx) => {
                  let className = 'assertBoxy';
                  if (app === selectedAssertion) className += ' active';
                  return (
                    <div key={idx} onClick={() => handleAssertionChange(app)} className={className}>
                      {app}
                    </div>
                  );
                })}
              </div>

              {appointmentState.loading &&
              <span>
                <Loading centered={true} style={{}} />
              </span>
              }

              {appointmentState.appointments.length === 0
              && appointmentState.isLoaded === true &&
              <span>
                К сожалению, на выбранную дату нет свободного времени.
              </span>
              }

              {selectedAssertion &&
              <>
                <hr className={'afterDate'}/>

                <span className='label'>Ваше имя</span>
                <input type="text" value={name}
                       onChange={(e) => setName(e.target.value)}
                       className={'assertInput'}/>
                <span className='label'>Телефон для связи</span>
                <PhoneInput
                  placeholder="Введите телефон"
                  value={phone}
                  onChange={setPhone}/>
                <span className='label'>Эл. почта (Опционально)</span>
                <input type="text" value={email}
                       onChange={(e) => setEmail(e.target.value)}
                       className={'assertInput'}/>

                <div className={getMainButtonClass()} onClick={handleCheckAssertion}>
                  <div>
                    Записаться
                  </div>
                </div>
              </>
              }
            </div>
          }
        </>
        }


      </div>
    </CSSTransition>
  );
};

export default AssignModal;
