import axios from "axios";

export const FETCH_APPOINTMENT_BEGIN = 'FETCH_APPOINTMENT_BEGIN';
export const FETCH_APPOINTMENT_SUCCESS = 'FETCH_APPOINTMENT_SUCCESS';
export const FETCH_APPOINTMENT_FAILURE = 'FETCH_APPOINTMENT_FAILURE';

export const CREATE_APPOINTMENT_BEGIN = 'CREATE_APPOINTMENT_BEGIN';
export const CREATE_APPOINTMENT_SUCCESS = 'CREATE_APPOINTMENT_SUCCESS';
export const CREATE_APPOINTMENT_FAILURE = 'CREATE_APPOINTMENT_FAILURE';

export const RESET_APPOINTMENT_STATE = 'RESET_APPOINTMENT_STATE';

const apiEndPoint = process.env['REACT_APP_BACKEND_API'];

export const createAppointment = (landing_name, offer_id, data) => {
  return dispatch => {
    dispatch(createAppointmentBegin());

    const request = axios.post(`${apiEndPoint}/landing/${landing_name}/create_appointment/${offer_id}`, data);

    request
      .then(data => {
        dispatch(createAppointmentSuccess(data['data']));
      })
      .catch(error => {
        dispatch(createAppointmentFailure(error));
      });
  };
};

export const resetAppointmentState = () => ({
  type: RESET_APPOINTMENT_STATE
});

export const createAppointmentBegin = () => ({
  type: CREATE_APPOINTMENT_BEGIN
});

export const createAppointmentSuccess = appointmentData => ({
  type: CREATE_APPOINTMENT_SUCCESS,
  payload: appointmentData
});

export const createAppointmentFailure = error => ({
  type: CREATE_APPOINTMENT_FAILURE,
  payload: {error}
});

export const fetchAppointmentsData = (landing_name, data) => {
  return dispatch => {
    dispatch(fetchAppointmentsBegin());

    const request = axios.post(`${apiEndPoint}/landing/${landing_name}/get_appointment`, data);

    request
      .then(data => {
        dispatch(fetchAppointmentsSuccess(data['data']));
      })
      .catch(error => {
        dispatch(fetchAppointmentsFailure(error));
      });
  };
};

export const fetchAppointmentsBegin = () => ({
  type: FETCH_APPOINTMENT_BEGIN
});

export const fetchAppointmentsSuccess = offersData => ({
  type: FETCH_APPOINTMENT_SUCCESS,
  payload: offersData
});

export const fetchAppointmentsFailure = error => ({
  type: FETCH_APPOINTMENT_FAILURE,
  payload: {error}
});
