import {FETCH_OFFERS_BEGIN, FETCH_OFFERS_FAILURE, FETCH_OFFERS_SUCCESS} from "../actions/offersActions";

const initialState = {
  offers: [],
  loading: true,
  error: null
};

export default function offersReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_OFFERS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_OFFERS_SUCCESS:
      return {
        ...state,
        loading: false,
        offers: action.payload.offers
      };

    case FETCH_OFFERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        offers: []
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
