import React from "react";


const Loading = ({centered, absoluted, style}) => {
  const classNames = require('classnames');
  const loaderClass = classNames({
    loader: true,
    center: centered,
    absolute: absoluted
  });

  return(
      <div style={style}>
        <span className={loaderClass}>
          <img src={'/loader.svg'} alt="Masterist"/>
        </span>
      </div>
  );
};

export default Loading;
