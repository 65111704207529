import axios from "axios";

export const FETCH_LANDING_BEGIN   = 'FETCH_LANDING_BEGIN';
export const FETCH_LANDING_SUCCESS = 'FETCH_LANDING_SUCCESS';
export const FETCH_LANDING_FAILURE = 'FETCH_LANDING_FAILURE';

const apiEndPoint = process.env['REACT_APP_BACKEND_API'];

export const fetchLandingData = (landing_name) => {
  return dispatch => {
    dispatch(fetchLandingBegin());

    // const request = axios.get(`http://dummy.restapiexample.com/api/v1/employees`);
    const request = axios.get(`${apiEndPoint}/landing/${landing_name}`);

    request
      .then(data => {
        console.log(data);
        dispatch(fetchLandingSuccess(data['data']));
      })
      .catch(error => {
        dispatch(fetchLandingFailure(error));
      });
  };
};

export const fetchLandingBegin = () => ({
  type: FETCH_LANDING_BEGIN
});

export const fetchLandingSuccess = landingData => ({
  type: FETCH_LANDING_SUCCESS,
  payload: { landingData }
});

export const fetchLandingFailure = error => ({
  type: FETCH_LANDING_FAILURE,
  payload: { error }
});
