import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchLandingData} from "../redux/actions/landingActions";
import {useParams} from "react-router";
import Loading from "../shared_components/loading";
import {AvatarBox, HeaderBg, ImageBox, MainBox, OfferImgBox, Sbutton, SbuttonBox} from "./main_styles";
import './main.scss';
import PhotoModal from "../shared_components/photo_modal/photo_modal";
import AssignModal from "../shared_components/assign_modal/assign_modal";
import {appModalClose, appModalOpen} from "../redux/actions/appActions";

const Main = () => {
  const state = useSelector(state => state.landingReducer);
  const [counter, setCounter] = useState(0);
  const [openAssign, setOpenAssign] = useState(false);
  const [selectedModalImage, setSelectedModalImage] = useState(null);
  const dispatch = useDispatch();
  const {landing_name} = useParams();

  useEffect(() => {
    console.log(process.env);
    dispatch(fetchLandingData(landing_name));
  }, []);

  const openModal = () => {
    console.log("Open modal");
    setOpenAssign(true);
    dispatch(appModalOpen());
  };

  const closeModal = () => {
    console.log("Close modal");
    setOpenAssign(false);
    dispatch(appModalClose());
  };

  let headerStyle;
  if (!state.loading && !state.error) {
    headerStyle = {
      backgroundImage: `url(${state.landingData.public_landing_profile.bg})`
    };
  }

  return (
    <div className="App">
      {state.loading && !state.error ?
        <Loading absoluted={true} centered={true}/>
        :
        <div>
          <HeaderBg className={'HeaderBg'} img={state.landingData.public_landing_profile.bg}/>
          <AvatarBox className={'AvatarBox'} img={state.landingData.public_profile.avatar}/>
          <div className={'MainBox'}>
            <h1 className={'center'}>
              {state.landingData.public_landing_profile.title}
            </h1>
            <span className="center">
              {state.landingData.public_landing_profile.subtitle}
            </span>
            <SbuttonBox className={'SbuttonBox center'}>
              {state.landingData.public_landing_profile.vk &&
              <Sbutton onClick={() => {
                window.open(`https://vk.me/${state.landingData.public_landing_profile.vk}`, '_blank');
              }} className={'Sbutton center'} img={'vk'}/>
              }
              {state.landingData.public_landing_profile.telegram &&
              <Sbutton onClick={() => {
                window.open(`tg://resolve?domain=${state.landingData.public_landing_profile.telegram}`, '_blank');
              }} className={'Sbutton center'} img={'tg'}/>
              }
              {state.landingData.public_landing_profile.facebook &&
              <Sbutton onClick={() => {
                window.open(`https://m.me/${state.landingData.public_landing_profile.facebook}`, '_blank');
              }} className={'Sbutton center'} img={'fb'}/>
              }
              {state.landingData.public_landing_profile.instagram &&
              <Sbutton onClick={() => {
                window.open(`https://www.instagram.com/${state.landingData.public_landing_profile.instagram}`, '_blank');
              }} className={'Sbutton center'} img={'insta'}/>
              }
              {state.landingData.public_landing_profile.whatsapp &&
              <Sbutton onClick={() => {
                window.open(`https://api.whatsapp.com/send?phone=${state.landingData.public_landing_profile.whatsapp}`, '_blank');
              }} className={'Sbutton center'} img={'wa'}/>
              }
              {state.landingData.public_landing_profile.viber &&
              <Sbutton onClick={() => {
                window.open(`viber://add?number=${state.landingData.public_landing_profile.viber}`, '_blank');
              }} className={'Sbutton center'} img={'viber'}/>
              }
            </SbuttonBox>

            <div onClick={openModal} className="MainButton">
              <div>
                Записаться онлайн
              </div>
            </div>
          </div>

          <div className="InfoBox">
            <h2>
              О мастере
            </h2>
            <span>
              {
                state.landingData.public_landing_profile.about.split('\n').map((item, i) => {
                  return(<p key={i}>{item}</p>);
                })
              }
            </span>

            <h2>
              Услуги
            </h2>
          </div>


          <div className="OfferCarousel">
            {(state.landingData.landing_offers).map((offer, idx) => {
              return (
                <div key={idx} className="OfferBox">
                  <OfferImgBox className="OfferImgBox" img={offer.image}/>
                  <h3>
                    {offer.title}
                  </h3>
                  <p>
                    { offer.description }
                  </p>
                </div>
              );
            })}
          </div>

          <div className="BottomInfoBox">
            <h2>
              Галлерея
            </h2>

            <div className="galleryBox">
              {state.landingData.public_landing_profile.images.map((image, idx) => {
                return (
                  <ImageBox onClick={() => {
                    console.log('Clicked', image);
                    setSelectedModalImage(image);
                  }} className={'ImageBox'} key={idx} img={image}/>
                );
              })}
            </div>
          </div>

          <div className="Footer" onClick={() => {
            window.open('https://masterist.ru', '_blank');
          }}>
            Сделано с помощью
            <span className={'link'}>
              <img src={'/logo-color-alt.png'} alt="Masterist"/>
              Masterist
            </span>
          </div>

          <PhotoModal image={selectedModalImage} onDismiss={() => setSelectedModalImage(null)}/>
          <AssignModal landing_name={landing_name} opened={openAssign} landing={state.landingData}
                       onClose={closeModal}/>
        </div>
      }
    </div>
  );
};

export default Main;
