import {
  FETCH_APPOINTMENT_BEGIN,
  FETCH_APPOINTMENT_SUCCESS,
  FETCH_APPOINTMENT_FAILURE,
  CREATE_APPOINTMENT_BEGIN,
  CREATE_APPOINTMENT_SUCCESS,
  CREATE_APPOINTMENT_FAILURE,
  RESET_APPOINTMENT_STATE
} from "../actions/appointmentActions";

const initialState = {
  appointments: [],
  createAppointmentResponse: null,
  appointmentCreating: false,
  loading: false,
  isLoaded: false,
  error: null
};

export default function appointmentReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_APPOINTMENT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        isLoaded: false,
        appointments: []
      };

    case FETCH_APPOINTMENT_SUCCESS:
      console.log("appointmentReducer received " + action.type, action.payload);
      return {
        ...state,
        loading: false,
        isLoaded: true,
        appointments: action.payload.body
      };

    case FETCH_APPOINTMENT_FAILURE:
      return {
        ...state,
        loading: false,
        isLoaded: false,
        error: action.payload.error,
        appointments: []
      };

    case CREATE_APPOINTMENT_BEGIN:
      return {
        ...state,
        appointmentCreating: true,
        error: null
      };

    case CREATE_APPOINTMENT_SUCCESS:
      console.log("appointmentReducer received " + action.type, action.payload);
      return {
        ...state,
        appointmentCreating: false,
        createAppointmentResponse: action.payload
      };

    case CREATE_APPOINTMENT_FAILURE:
      return {
        ...state,
        appointmentCreating: false,
        createAppointmentResponse: action.null,
        error: action.payload.error
      };

    case RESET_APPOINTMENT_STATE:
      console.log("Resetting appointment state");
      return initialState;

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
